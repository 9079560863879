<template>
  <v-col>
    <v-row class="text-h6">
      <v-col cols="6">
        Shipment {{ count }}
        <v-btn icon large @click="openLabel()">
          <v-icon>
            receipt
          </v-icon>
        </v-btn>
        <v-btn icon large @click="notifyCustomerHandler()">
          <v-icon>
            chat_bubble
          </v-icon>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="1">
        <v-btn icon large @click="deleteDialog = true">
          <v-icon>
            delete
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="text-caption">
      {{ service }} {{ this.shipment.shipEngine.tracking_number }}<br />
      Created on {{ new Date(shipment.created_at).toDateString() }} by
      {{ shippedBy.firstName }} {{ shippedBy.lastName }}<br />
      <div v-if="notifiedBy">
        Notifaction sent on
        {{ new Date(shipment.notificationSentAt).toDateString() }} by
        {{ notifiedBy.firstName }} {{ notifiedBy.lastName }}
      </div>
    </div>
    <v-data-table
      style="background-color:inherit;"
      dense
      disable-pagination
      hide-default-footer
      disable-sort
      :items="shipment.lineItems"
      :headers="[
        { text: 'Qty', value: 'quantity' },
        { text: 'Product', value: 'title' },
        { text: 'Options', value: 'variant_title' },
      ]"
    />
    <v-dialog v-model="deleteDialog" max-width="600">
      <v-card>
        <v-card-title>
          Are you sure you want to delete this shipment?
        </v-card-title>
        <v-card-actions>
          <v-btn width="50%" @click="deleteShipmentHandler(shipment.id)">
            Yes
          </v-btn>
          <v-btn width="50%" @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
String.prototype.toProperCase = function() {
  return this.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
import strapiApi from '../mixins/strapiApi'
export default {
  name: 'Shipment',
  data: () => ({
    deleteDialog: false,
    shippedBy: '',
    notifiedBy: '',
  }),
  computed: {
    service() {
      return this.shipment.shipEngine.service_code
        .replaceAll('_', ' ')
        .toProperCase()
    },
  },
  props: { shipment: Object, count: Number },
  mixins: [strapiApi],
  methods: {
    async deleteShipmentHandler() {
      await this.deleteShipment(this.shipment.id)
      this.deleteDialog = false
      this.$emit('sync-order')
    },
    async notifyCustomerHandler() {
      await this.shipmentNotify(this.shipment.id, this.$store.state.user)
      this.$emit('sync-order')
    },
    openLabel() {
      window.open(this.shipment.label.href)
    },
  },
  async mounted() {
    this.shippedBy = await this.getUser(this.shipment.shippedBy)
    this.notifiedBy = await this.getUser(this.shipment.notifiedBy)
  },
}
</script>
