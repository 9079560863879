<template>
  <v-container>
    <v-row class="text-h5">
      <v-col xs="12" sm="6">
        <span>
          Orders
        </span>
        <v-btn
          @click="toggleUrgentFilter()"
          rounded
          :outlined="!urgentFilterActive"
          color="red"
          v-if="urgentCount > 0"
          >{{ urgentCount }} urgent</v-btn
        >
      </v-col>
      <v-col xs="12" sm="6">
        <v-select
          multiple
          :item-value="(item) => item.id"
          :items="orderedCollections"
          :item-text="(item) => item.name"
          v-model="selectedCollectionIds"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleSelectedCollectionIds()">
              <v-list-item-action>
                <v-icon
                  :color="
                    selectedCollectionIds.length > 0 ? 'indigo darken-4' : ''
                  "
                >
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ selectMessage }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.title }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text caption"
              >(+{{ selectedCollectionIds.length - 2 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="filteredOrders.map((order) => order.shopifyJson).sort((a,b) => {
            return a.number > b.number})"
          :itemid="filteredOrders.map((order) => order.id)"
          :headers="headers"
          :loading="loading"
          :loading-text="loadingText"
          :expanded.sync="expanded"
          single-expand
          @click:row="showDetail"
          @item-expanded="onExpand"
          show-expand
          disable-sort
          disable-pagination
          hide-default-footer
          :no-data-text="noDataText"
          
        >
          <template v-slot:item.created_at="{ item }">
            {{ new Date(item.created_at).toLocaleString() }}
          </template>
          <template v-slot:item.age="{ item }">
            {{
              Math.round(
                (new Date(Date.now()).getTime() -
                  new Date(item.created_at).getTime()) /
                  1000 /
                  60 /
                  60 /
                  24
              )
            }}
          </template>
          <template v-slot:item.closed_at="{ item }">
            {{
              item.closed_at == null
                ? null
                : new Date(item.closed_at).toLocaleString()
            }}
          </template>
          <template v-slot:item.strapiId="{ item }">
            {{ getStrapiId(item.id) }}
          </template>
          <template v-slot:item.sync="{ item }">
            <v-btn icon x-small @click="sync(getStrapiId(item.id))">
              <v-icon> loop </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.link="{ item }">
            <v-btn icon x-small @click="openLink(item.id)">
              <v-icon> link </v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length" class="order-detail-td">
              <v-progress-linear v-if="syncingItem" indeterminate />
              <order-detail
                v-else
                :order="orders.find((order) => order.shopifyId == item.id)"
                :shippingPackages="shippingPackages"
                @sync-order="sync($event)"
              />
            </td>
          </template>
          <template v-slot:item.urgent="{ item }">
            <v-icon color="red" v-if="item.tags.includes('urgent')">
              priority_high
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import strapiApi from '../mixins/strapiApi'
import OrderDetail from './OrderDetail.vue'
export default {
  name: 'OrderTable',
  mixins: [strapiApi],
  components: { OrderDetail },
  data: () => ({
    loading: true,
    shippingPackages: [],
    loadingText: '',
    noDataText: '',
    orders: [],
    collections: [],
    headers: [
      //{ text: 'Strapi Id', value: 'strapiId' },
      { text: 'Number', value: 'name' },
      { text: 'Order Placed', value: 'created_at' },
      { text: 'Age (days)', value: 'age' },
      { text: 'Customer', value: 'shipping_address.name' },
      { text: 'Closed At', value: 'closedAt ' },
      { text: 'Sync', value: 'sync' },
      { text: 'Shopify', value: 'link' },
      { text: 'Urgent', value: 'urgent' },
      { text: 'sid', value: 'strapiId' },
      { text: 'Details', value: 'data-table-expand' },
    ],
    order: null,
    detailDialog: false,
    expanded: [],
    syncingItem: false,
    urgentFilterActive: false,
  }),
  async mounted() {
    try {
      this.loadingText = 'Syncing Strapi and Shopify order records'
      await this.syncEndpoint('orders', '&status=any')
      this.loadingText = 'Syncing Strapi and Shopify product records'
      await this.syncEndpoint('products')
      this.loadingText = 'Syncing Strapi and Shopify collection records'
      await this.syncEndpoint('collections')
      this.loadingText = 'Getting strapi collections'
      this.collections = await this.getCollections()
      this.collections = this.collections.map((c) => {
        c.products = c.products.map((p) => p.shopifyId)
        return c
      })
      this.shippingPackages = await this.strapiGet('packages')
      this.loadingText = 'Getting strapi orders'
      this.orders = await this.getOrders('closedAt_null=true&_limit=-1')
      this.loading = false
    } catch (error) {
      this.noDataText = 'Error loading orders. Is Strapi service running?'
      this.loading = false
      console.error(error)
    }
  },
  computed: {
    urgentCount() {
      return this.orders.filter((o) => {
        return o.shopifyJson.tags.includes('urgent')
      }).length
    },
    filteredOrders() {
      if (this.urgentFilterActive) {
        return this.orders.filter((o) => o.shopifyJson.tags.includes('urgent'))
      }
      return this.orders.filter((o) => {
        return o.shopifyJson.line_items.some((li) => {
          if (li.product_id == null) return false
          return (
            this.selectedProductIds.includes(li.product_id.toString()) &&
            li.fulfillable_quantity > 0
          )
        })
      })
    },
    orderedCollections() {
      let orderedProductIds = Array.from(
        new Set(
          this.orders.flatMap((order) => {
            return order.shopifyJson.line_items.map(
              (lineitem) => lineitem.product_id
            )
          })
        )
      )
      const orderedCollections = this.collections
        .filter((collection) => {
          return orderedProductIds.some((id) => {
            return (
              collection.products.some((productId) => productId == id) &&
              collection.name != 'All Products' &&
              collection.name != 'On Sale Now'
            )
          })
        })
        .sort((a, b) => a.name > b.name)

      return orderedCollections
    },
    selectedProductIds() {
      return this.selectedCollectionIds.flatMap((cid) => {
        return this.collections.find((c) => c.id == cid).products
      })
    },
    selectedCollectionIds: {
      get() {
        return this.$store.state.selectedCollectionIds
      },
      set(value) {
        return this.$store.commit('setSelectedCollectionIds', value)
      },
    },
    icon() {
      if (this.orderedCollections.length === this.selectedCollectionIds.length)
        return 'check_box'
      if (
        this.selectedCollectionIds.length > 0 &&
        this.selectedCollectionIds.length < this.orderedCollections.length
      ) {
        return 'indeterminate_check_box'
      }
      return 'check_box_outline_blank'
    },
    selectMessage() {
      if (this.orderedCollections.length === this.selectedCollectionIds.length)
        return 'Select None'
      return 'Select All'
    },
  },
  methods: {
    getStrapiId(gid) {
      return this.orders.find((order) => order.shopifyId == gid).id
    },
    showDetail(e, payload) {
      if (this.expanded[0]) {
        if (this.expanded[0].id == payload.item.id) {
          return (this.expanded = [])
        }
      }
      this.expanded = [payload.item]
      this.onExpand(payload)
    },
    async sync(id) {
      this.syncingItem = true
      return await this.syncOrder(id)
        .then((res) => {
          this.updateOrder(res.data)
          this.syncingItem = false
        })
        .catch((err) => {
          console.error(err)
          this.syncingItem = false
        })
    },
    updateOrder(order) {
      const index = this.orders.findIndex((o) => o.id == order.id)
      this.orders.splice(index, 1, order)
    },
    onExpand(payload) {
      this.syncingItem = true
      this.sync(this.getStrapiId(payload.item.id))
    },
    async syncAll() {
      for await (let order of this.orders) {
        this.sync(order.id).then((res) => {
          console.log(res)
        })
      }
    },
    openLink(id) {
      const url = `https://simple-wood-goods.myshopify.com/admin/orders/${id}`
      window.open(url)
    },
    toggleUrgentFilter() {
      this.urgentFilterActive = !this.urgentFilterActive
    },
    toggleSelectedCollectionIds() {
      this.$nextTick(() => {
        if (
          this.orderedCollections.length === this.selectedCollectionIds.length
        ) {
          this.selectedCollectionIds = []
        } else {
          this.selectedCollectionIds = this.orderedCollections.map((c) => c.id)
        }
      })
    },
  },
}
</script>
<style>
.order-detail-td {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-top-style: none;
  border-width: thin;
}
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f5f5f5;
}
</style>
