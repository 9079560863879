<template>
  <order-table />
</template>

<script>
import OrderTable from '../components/OrderTable.vue'

export default {
  name: 'Home',

  components: {
    OrderTable,
  },
}
</script>
