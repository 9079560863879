<template>
  <div>
    {{ address.name }} <br />
    {{ address.address1 }} <br />
    <div v-if="address.address2">{{ address.address2 }}<br /></div>
    {{ address.city }}, {{ address.province }} {{ address.zip }} <br/>
    {{ address.country}} <br/>
    {{ address.phone}}

  </div>
</template>
<script>
export default {
  name: 'Address',
  props: { address: Object },
}
</script>
