<template>
  <v-select
    v-model="status"
    :items="['Need', 'Cut', 'Glued', 'Complete']"
  ></v-select>
</template>

<script>
import strapiApi from '../mixins/strapiApi'

export default {
  name: 'LineItemStatus',
  props: { lineItem: Object },
  mixins: [strapiApi],
  computed: {
    status: {
      get() {
        return this.lineItem.status
      },
      set(val) {
        return this.updateLineItem(this.lineItem.id, { status: val })
      },
    },
  },
}
</script>

<style scoped>
.v-select {
  font-size: 0.875rem;
  margin-top: 8px;
}
</style>
